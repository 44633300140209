import React from "react";

const About = () => {
  return (
    <section className='section about-section'>
      <h1 className='section-title'>About Us</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates
        suscipit temporibus neque minus autem, dolore itaque doloribus debitis.
        Iste, excepturi nihil, similique mollitia nam voluptatem iusto obcaecati
        alias accusantium, sint quo unde quas saepe harum quos omnis asperiores.
        Excepturi, omnis.
      </p>
    </section>
  );
};

export default About;
